import React, { useContext } from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  Slide,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  DialogActions,
  Select,
} from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FaFacebookF, FaTwitter, FaChevronDown } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import SettingsContext from "src/context/SettingsContext";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    overflow: "hidden",
    background: theme.palette.background.grey,
    position: "relative",
    padding: "30px 0px",
    zIndex: "2",
    "& .textbox": {
      position: "relative",
      zIndex: "2",
      "& h2": {
        marginBottom: theme.spacing(2),
      },
    },
    "& .coprightBox": {
      [theme.breakpoints.only("xs")]: {
        marginTop: "-14px",
        marginBottom: "12px",
      },
    },

    "& .MuiButton-containedPrimary": {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    "& .MuiIconButton-sizeSmall": {
      border: " 1px solid #BDBCC3",
      padding: "14px",
      fontSize: "17px",
      color: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
        fontSize: "12px",
      },
    },

    "& p": {
      [theme.breakpoints.down("xs")]: {
        // fontSize: "12px !important",
        maxWidth: "100%",
      },
    },
    "& a": {
      color: "#fff",
      display: "flex",
      fontSize: "13px",
      alignItems: "center",
      fontWeight: "300",
      textDecoration: "none",
      lineHeight: "28px",
      cursor: "pointer",
      // textTransform: "uppercase",
      "&:hover": {
        color: "#E73725",
        textDecoration: "none",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        lineHeight: "10px",
      },
    },
  },
  FooterBotom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  formContact: {
    width: "450px",
    maxWidth: "100%",
    "& h6": { fontWeight: "600" },
    "& label": {
      color: theme.palette.primary.main,
      marginBottom: "8px",
      display: " inline-block",
      fontWeight: "600",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Liquidity() {
  const themeSeeting = useContext(SettingsContext);
  const classes = useStyles();
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    window.open('https://blockstatshelp.zendesk.com/', '_blank');
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Box className="textbox" textAlign="center" mb={5}>
            <Box my={3}>
              <Typography variant="h2" color="primary">
                Still Have Questions?
              </Typography>

              <Typography variant="h6" color="secondary">
                Our customer support service always ready to help you
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Contact Us
            </Button>
          </Box>

          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <Box className={classes.FooterBotom}>
            <Box mt={1}>
              <LazyLoadImage
                effect="blur"
                alt="download Ios"
                // height={image.height}
                src={
                  themeSeeting.settings.theme === "LIGHT"
                    ? "images/logo_dark.svg"
                    : "images/logo_light.svg"
                }
                // width={image.width}
              />
            </Box>
            <Box className="displayEnd bottomText" my={2}>
              <Typography variant="body2" color="secondary">
                © 2024 PortfolioTracker. All Rights Reserved.
              </Typography>
            </Box>
            <Box className="displaySpacebetween displayColumn">
              <Box className="displayStart coprightBox"></Box>
              <Box className="displayStart">
                <IconButton
                  aria-label="Facebook"
                  size="small"
                  onClick={() => window.open(" https://www.facebook.com/")}
                >
                  <FaFacebookF />
                </IconButton>
                <IconButton
                  aria-label="twitter"
                  size="small"
                  onClick={() => window.open(" https://twitter.com/")}
                >
                  <FaTwitter />
                </IconButton>
                <IconButton
                  size="small"
                  aria-label="linkedin"
                  onClick={() => window.open("https://www.linkedin.com/company/blockstats/about/")}
                >
                  <GrLinkedinOption />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Box className="dflexBetween">
            <Typography variant="h6" color="primary">
              Contact Us
            </Typography>
            <IconButton className="closeButton" onClick={handleClose}>
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form className={classes.formContact} noValidate autoComplete="off">
            <Box mb={2}>
              <label>Email</label>
              <TextField
                id="outlined-basic"
                label="Enter your email"
                fullWidth
                variant="outlined"
              />
            </Box>
            <Box mb={2}>
              <label>Your Name</label>
              <TextField
                id="outlined-basic1"
                label="Enter your name"
                fullWidth
                variant="outlined"
              />{" "}
            </Box>
            <Box mb={2}>
              <label>Topic</label>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Age
                </InputLabel>
                <Select
                  IconComponent={FaChevronDown}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={age}
                  onChange={handleChange}
                  label="Age"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mb={2}>
              <label>Message</label>
              <TextField
                id="outlined-basic2"
                label="Tell us what you think..."
                fullWidth
                variant="outlined"
                multiline
                minRows="5"
              />{" "}
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
