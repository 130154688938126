import { Box, Dialog, DialogContent, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  dialogMain: {
    '& .MuiDialog-paperScrollPaper': {
      background: 'transparent !important',
    },
    '& .MuiPaper-elevation24': {
      boxShadow: 'none !important',
    },
  },
}))
export default function ScreenLoader({ open, close }) {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      fullWidth
      className={classes.dialogMain}
      style={{
        background: 'transparent !important',
        backdropFilter: 'blur(3px)',
        width: '100%',
        height: '100%',
      }}
      onClose={() => {
        if (!open) {
          close()
        }
      }}
    >
      <DialogContent
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <img src="/images/loading.svg" alt="" width="200px" />
          <Typography
            variant="body1"
            style={{ color: '#fff', marginTop: '10px' }}
          >
            Please wait...
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
