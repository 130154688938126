//import { BASE_URL } from 'src/constant'
export const api_base_url = 'https://blockstats-gateway-7kx0sox4.uc.gateway.dev'
//const api_base_url2 = 'https://aggregator-service-uw6myk4s3a-uc.a.run.app'
export const api_base_url_3 = 'https://analytics-service-594081517864.us-central1.run.app'
let version = 'v1'
let walleturl = `${api_base_url}/blockstats/walletservice/${version}`
let aggregatorurl = `${api_base_url}/blockstats/aggregator/${version}`
let portfolio = `${api_base_url}/blockstats/portfolioservice/${version}`
let analytics = `${api_base_url_3}/blockstats/analyticsservice/${version}`
let defi = `${aggregatorurl}/defi`
//"https://analytics-service-594081517864.us-central1.run.app/blockstats/analyticsservice/v1/portfolio/summary/8prcdcHvnRQzVPMY1GT2bUCkALu1'
//"https://analytics-service-594081517864.us-central1.run.app/blockstats/analyticsservice/v1/cost-analytics/unrealized-gains/8prcdcHvnRQzVPMY1GT2bUCkALu1"
//"https://analytics-service-594081517864.us-central1.run.app/blockstats/analyticsservice/v1/portfolio/24hour-high/8prcdcHvnRQzVPMY1GT2bUCkALu1"
//"https://analytics-service-594081517864.us-central1.run.app/blockstats/analyticsservice/v1/transaction/summary/8prcdcHvnRQzVPMY1GT2bUCkALu1?startDate=2023-01-01T00:00:00&endDate=2024-12-12T00:00:00"
//"https://analytics-service-594081517864.us-central1.run.app/blockstats/analyticsservice/v1/transaction/summary/8prcdcHvnRQzVPMY1GT2bUCkALu1?startDate=2023-01-01T00:00:00&endDate=2024-12-12T00:00:00"
//"https://analytics-service-594081517864.us-central1.run.app/blockstats/analyticsservice/v1/portfolio/summary/8prcdcHvnRQzVPMY1GT2bUCkALu1
const ApiConfig = {
  wallet: `${walleturl}/wallets`,

  //aggregator_services_api
  spotHoldings: `${aggregatorurl}/accounts`,
  openOrders: `${aggregatorurl}/orders`,
  transactions: `${aggregatorurl}/transactions`,
  //margins: `${aggregatorurl}/margins`,
  futures: `${aggregatorurl}/futures`,

  //portfolio_services_api
  load: `${portfolio}/portfolio/sync`,
  walletStatus: `${portfolio}/status`,
  unrealizedGain: `${analytics}/cost-analytics/unrealized-gains`,
  bestPerformer: `${analytics}/portfolio/24hour-high`,
  allocation: `${analytics}/portfolio/assets`,

  //aggregator_defi_services_api
  defiAccounts: `${defi}/accounts`,
  defiTransactions: `${defi}/transactions`,
  defiStakings: `${defi}/stakings`,

  //api_to_get_current_balances
  currentBalance: `${aggregatorurl}/accounts/currentBalance`,
  marginsBalance: `${aggregatorurl}/margins/currentBalance`,
  futuresBalance: `${aggregatorurl}/futures/currentBalance`,

  //analytics_services_api
  bubbleMap: `${analytics}/portfolio/24hour-change`, 
  depositsWithdrawal: `${analytics}/transaction/summary`, 
  summary: `${analytics}/portfolio/summary`, 
}

export default ApiConfig