import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Container,
  Paper,
  Dialog,
  Hidden,
  Popper,
  Avatar,
  Typography,
  Select,
  Switch,
  withStyles,
  ClickAwayListener,
} from '@material-ui/core'
import Menu from '@mui/material/Menu';
import { FaUserEdit } from 'react-icons/fa'
import SettingsContext from 'src/context/SettingsContext'
import SideBar from 'src/layouts/DashboardLayout/SideBar/index'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Logo from 'src/component/Logo'
import { FaChevronDown } from 'react-icons/fa'
import { RiSettings4Line } from 'react-icons/ri'
import { CgClose } from 'react-icons/cg'
import SearchField from './SearchField'
import { NavLink } from 'react-router-dom'
import ImageIconChecked from './moon.svg'
import ImageIconUnchecked from './sun.svg'
import { AuthContext } from 'src/context/Auth'
import { generateUniqueAvatar } from 'src/utils'
import wallet from 'src/views/pages/Wallet'

const headersData = [
  {
    label: 'Portfolio',
    href: '/portfolio',
  },
  // {
  //   label: 'Performance',
  //   href: '/performance',
  // },
  {
    label: 'Transactions',
    href: '/transaction',
  },
  {
    label: 'Wallets',
    href: '/wallet',
  },
  {
    label: 'Vote',
    href: '/vote',
  },
]

const useStyles = makeStyles((theme) => ({
  listBox:{
    '&.MuiList-root-MuiMenu-list':{
      paddingTop:"0px !important",
      paddingBottom:"0px !important"
    }
  },
  appbar: {
    background: theme.palette.background.main,
    borderBottom: `1px solid ${theme.palette.background.border} !important`,
    borderRadius: 0,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      backgroundColor: theme.palette.background.main,
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },

  inputRoot: {
    borderRadius: theme.shape.borderRadius,
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.bg4box,
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    color: `${theme.palette.secondary.main} !important`,
    marginRight: '20px',
    fontSize: '14px',
    fontWeight: '600',
    '&.active': {
      color: `${theme.palette.primary.main} !important`,
      position: 'relative',
      '&::before': {
        content: '" "',
        position: 'absolute',
        bottom: '-22px',
        left: '-1px',
        borderBottom: '2px solid #7d56e7',
        width: '100%',
        height: '2px',
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  drawerContainer: { maxWidth: '95%', margin: '0 auto' },
  menuButton1: {
    width: '100%',
    padding: '6px 0',
    '& li': { width: '100%', borderRadius: '8px' },
  },
}))
const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: 'white',
    '&$checked': {
      transform: 'translateX(16px)',
      color: 'white',
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.background.main,
        borderColor: 'transparent',
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 30,
    opacity: 1,
    backgroundColor: theme.palette.background.main,
    borderColor: 'transparent',
  },
  checked: {},
}))(Switch)
export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null)
  //const history = useHistory()
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)
  const themeSeeting = useContext(SettingsContext)
  const auth = useContext(AuthContext)
  const { userData } = auth
  const [disableHeaders, setDisableHeaders] = useState(false);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    auth.userLogIn(false, ""); // Reset authentication state
    window.localStorage.removeItem("accessToken"); 
    history.push("/"); // Redirect to home or login page
  };
  const handleMenuItemClick = (path) => {
  history.push(path); // Navigate to the specified path
  handleMenuClose(); // Close the dropdown menu
};
const helpSupport =() =>{
  window.open('https://blockstatshelp.zendesk.com/', '_blank');
}
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    })
  }
  const [open1, setOpen1] = useState(false)
  const {
    menuMobile,
    menuButton,
    menuButton1,
    drawerContainer,
    drawericon,
    mainHeader,
  } = useStyles()
  const history = useHistory()

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  })
  const [age, setAge] = React.useState('')

  const handleChange = (event) => {
    setAge(event.target.value)
  }
  const { mobileView, drawerOpen } = state
  //popper
  const [open, setOpen] = useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }
  //popper
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  const { userAddedWallets } = useContext(AuthContext);
  console.log('Fetched wallets in userAddedWallets:', userAddedWallets);
 
  const displayDesktop = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          style={{ width: '250px' }}
        >
          {femmecubatorLogo}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {getMenuButtons()}
        </Box>
        <div className={classes.grow} />
        <SearchField />
        <IconButton
          className="themeset"
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <RiSettings4Line style={{ color: 'rgb(84 84 84)' }} />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          placement="bottom-end"
          style={{ minWidth: '220px' }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper elevation={4}>
              <Box className="dflexBetween">
                <Typography variant="body2" color="secondary">
                  Theme
                </Typography>
                <CustomSwitch
                  icon={<img src={ImageIconUnchecked} alt="Unchecked Icon" />}
                  checkedIcon={
                    <img src={ImageIconChecked} alt="Checked Icon" />
                  }
                  checked={themeSeeting.settings.theme === 'DARK'}
                  onChange={(event) => {
                    const newTheme = event.target.checked ? 'DARK' : 'LIGHT'
                    changeTheme(newTheme)
                  }}
                  color="primary"
                  inputProps={{ 'aria-label': 'Change Theme' }}
                />
              </Box>
              <Box my={2} className="dflexBetween">
                <Typography variant="body2" color="secondary">
                  Language
                </Typography>
                <Select
                  IconComponent={FaChevronDown}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </Box>
              <Box className="dflexBetween">
                <Typography variant="body2" color="secondary">
                  Currency
                </Typography>
                <Select
                  IconComponent={FaChevronDown}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>

        <Box>
        <>
      <Avatar
        style={{ marginLeft: '10px', cursor: 'pointer' }}
        src='images/profileIcon.svg'
        onClick={handleAvatarClick}
      />
<Menu
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  PaperProps={{
    style: {
      backgroundColor: 'transparent', 
    },
  }}
>
        <MenuItem onClick={() => handleMenuItemClick('/profile')}>
  <Box display="flex" alignItems="center">
  <img
    src="/images/profile.svg"
    alt="Profile"
    style={{ width: 24, height: 24 }}
  />
    <Typography variant="body2" style={{ marginLeft: 10 ,color:"#83818E",fontFamily:'"Manrope", Helvetica', fontSize:"12px"}}>
      Profile Information
    </Typography>
  </Box>
</MenuItem>
        {/* <MenuItem onClick={() => handleMenuItemClick('/security')}>
          <Box display="flex" alignItems="center">
          <img
    src="/images/security.svg"
    alt="Profile"
    style={{ width: 24, height: 24 }}
  />
            <Typography variant="body2" style={{ marginLeft: 10 ,color:"#83818E",fontFamily:'"Manrope", Helvetica', fontSize:"12px"}}>
              Security Settings
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('/notifications')}>
          <Box display="flex" alignItems="center">
          <img
    src="/images/notification.svg"
    alt="Profile"
    style={{ width: 24, height: 24 }}
  />
            <Typography variant="body2" style={{ marginLeft: 10,color:"#83818E",fontFamily:'"Manrope", Helvetica', fontSize:"12px" }}>
              Notification Preferences
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('/billing')}>
          <Box display="flex" alignItems="center">
          <img
    src="/images/subscription.svg"
    alt="Profile"
    style={{ width: 24, height: 24 }}
  />
            <Typography variant="body2" style={{ marginLeft: 10 ,color:"#83818E",fontFamily:'"Manrope", Helvetica', fontSize:"12px"}}>
              Subscription and Billing
            </Typography>
          </Box>
        </MenuItem> */}
        <MenuItem onClick={helpSupport}>
          <Box display="flex" alignItems="center">
          <img
    src="/images/help.svg"
    alt="Profile"
    style={{ width: 24, height: 24 }}
  />
            <Typography variant="body2" style={{ marginLeft: 10,color:"#83818E",fontFamily:'"Manrope", Helvetica', fontSize:"12px" }}>
              Help & Support
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleLogout('/support')}>
          <Box display="flex" alignItems="center">
          <img
    src="/images/logout.svg"
    alt="Profile"
    style={{ width: 24, height: 24 }}
  />
            <Typography variant="body2" style={{ marginLeft: 10,color:"#E74238",fontFamily:'"Manrope", Helvetica', fontSize:"12px" }}>
              Logout
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>

        </Box>
      </>
    )
  }
  const paperProps = {
    style: {
      top: 'auto',
      bottom: 0,
    },
  }
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }))
    //mobile
    return (
      <Toolbar className={mainHeader} style={{ width: '100%' }}>
        <Hidden lgUp>
          <Dialog
            fullScreen
            open={open1}
            anchor={'bottom'}
            onClose={() => setOpen1(false)}
          >
            <Box px={2}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen1(false)}
                aria-label="close"
              >
                <FaUserEdit />
              </IconButton>
              <SideBar />
            </Box>
          </Dialog>
        </Hidden>
        <Drawer
          {...{
            anchor: 'right',
            open: drawerOpen,
            onClose: handleDrawerClose,
            PaperProps: paperProps,
          }}
        >
          <div className={drawerContainer}>
            <Box
              className="dflexBetween"
              style={{ position: 'relative', padding: '10px' }}
            >
              <Logo />
              <IconButton
                edge="start"
                color="secondary"
                onClick={handleDrawerClose}
                aria-label="close"
                className="closeButtonDrawer"
              >
                <CgClose style={{ color: '#83818E' }} />
              </IconButton>
            </Box>
            <Box>
              <SideBar />
            </Box>
            {getDrawerChoices()}

            <Box className="drawerbox"></Box>
          </div>
        </Drawer>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <Box display="flex" justifyContent="space-between">
            {femmecubatorLogo}
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              className={drawericon}
              {...{
                edge: 'start',
                color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: '#83818E', fontSize: '26px' }}
              />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    )
  }

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }, index) => {
      const isDisabled = disableHeaders && index < 4; // Disable first four headers
      return (
        <Button
          key={label}
          color="inherit"
          to={isDisabled ? '#' : href} // Prevent navigation if disabled
          component={Link}
          className={`${menuButton1} ${isDisabled ? 'disabled' : ''}`}
          style={isDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}
        >
          <MenuItem className={menuMobile}>{label}</MenuItem>
        </Button>
      );
    });
  };
  

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  )

  const getMenuButtons = () => {
    return headersData.map(({ label, href }, index) => {
      return (
        <NavLink
          exact
          key={label}
          color="inherit"
          to={href}
          className={classes.menuButton}
          activeClassName="active"
        >
          {label}
        </NavLink>
      );
    });
  };


  return (
    <>
      <AppBar position="relative" elevation={0} className={classes.appbar}>
        <Container maxWidth={false} style={{ padding: '0px' }}>
          <Toolbar> {mobileView ? displayMobile() : displayDesktop()}</Toolbar>
        </Container>{' '}
      </AppBar>
      {dialogOpen && (
        <Paper>
          <Dialog
            fullWidth
            maxWidth="lg"
            className={classes.searchdiaogBox}
            style={{
              position: 'absolute',
              top: '10%',
              // minHeight: "695px",
            }}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <IconButton
              className="closeButton"
              onClick={() => setDialogOpen(false)}
            >
              <CloseIcon style={{ color: '#AAAAAA' }} />
            </IconButton>

            <Box className="dialogBoxHeight">
              <CloseIcon style={{ color: '#AAAAAA' }} />
            </Box>
          </Dialog>
        </Paper>
      )}
    </>
  )
}
