import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  Paper,
} from "@mui/material";
import { Refresh, Delete } from "@mui/icons-material";
import { AuthContext } from "src/context/Auth";
import { useTheme, makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';
import UndoIcon from '@mui/icons-material/Undo';
import { toast } from 'react-hot-toast'
import {
  rawAPICall
} from "src/Apiconfig/service";
import ApiConfig from 'src/Apiconfig/config'
import AlertDialog from './utils/AlertDialog.tsx';
const useStyles = makeStyles((theme) => ({
  walletContainer: {
    //backgroundColor: theme.name === "light" ? "#F7F7F8" : "#0F0E14",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  contentContainer: {
    //backgroundColor: theme.name === "light" ? "#FFF" : "#0F0E14",
    overflow: "hidden",
    minHeight: 300,
    width: "90%",
    margin: "auto",
    padding: "20px",
  },
  walletCard: {
    backgroundColor: theme.name === "LIGHT" ? "#FFF !important" : "#1C1825 !important",
    color: theme.name === "LIGHT" ? "#000 !important" : "#FFF !important",
    borderRadius: "12px !important",
    padding: "16px !important",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1) !important",
    fontFamily: '"Manrope", Helvetica, sans-serif !important',
  },
  textClr: {
    color: theme.name === "LIGHT" ? "#000 !important" : "#FFF !important",
  },
  walletHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  walletIcon: {
    width: "40px",
    height: "40px",
    backgroundColor: "#5A5A5A",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: "bold",
  },
  walletDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  walletActions: {
    //color: theme.palette.text.secondary,
    color: 'white',
  },
  sectionTitle: {
    //color: theme.palette.text.secondary,
    fontSize: "14px",
  },
  sectionValue: {
    fontWeight: "bold",
    color: "inherit",
    fontSize: "20px",
  },
  footerText: {
    color: theme.palette.text.secondary,
    textAlign: "right",
    marginTop: "16px",
  },
}));

const Wallets = () => {
  const theme = useTheme();
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const userAddedWallets = auth?.userAddedWallets || [];
  const loading = auth?.isLoading || false;
  const [wallets, setWallets] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    isOpen: false,
    walletName: "",
    action: "",
    title: "",
    description: "",
  });
  const openDialog = (walletName, action) => {
    const actionTitles = {
      archive: "Archive Wallet",
      unarchive: "UnArchive Wallet",
      delete: "Delete Wallet",
    };
    const actionDescriptions = {
      archive: "Are you sure you want to Archive this Wallet?",
      unarchive: "Are you sure you want to UnArchive this Wallet?",
      delete: "Are you sure you want to Delete this wallet? This action cannot be undone.",
    };
    setDialogData({
      isOpen: true,
      walletName,
      action,
      title: actionTitles[action],
      description: actionDescriptions[action],
    });

  };
  const closeDialog = () => {
   // setIsDialogOpen(false);
    setDialogData({isOpen:false});
  };
  const handleAction = async (walletName, action) => {
    if (action === "archive") {
      handleArchiveWallet(walletName);
    } else if (action === "unarchive") {
      handleUnarchive(walletName);
    } else if (action === "delete") {
      handleDelete(walletName);
    }
    closeDialog();
  };
  useEffect(() => {
    console.log("Current Theme:", theme);
  }, [theme]);
  useEffect(() => {
    if (auth?.userAddedWallets) {
      setWallets(auth.userAddedWallets);
      // setArchiveWallets(auth.userAddedWallets.filter(wallet => wallet.archived))
      console.log("Wallets synced from AuthContext:", auth.userAddedWallets);
      //  console.log("Archieve Wallet :", archiveWallets);
    }
  }, [auth?.userAddedWallets]);

  const handleArchiveWallet = (walletName: string) => {
    console.log("Deleting wallet ID:", walletName);
    archiveWallet(walletName);

  };
  const archiveWallet = async (walletName: string) => {
    try {
      console.log("API Archive WALLET URL :" + `${ApiConfig["wallet"]}`)
      const response = await rawAPICall({
        method: "PUT",
        url: `${ApiConfig["wallet"]}` + "/" + auth?.userData?.uid + "/" + walletName + "/archive",
      });
      console.log("Respons : " + response);
      if (response.status == 200) {
        const result = await response.data;
        auth.userAddedWallets = result;
        // Update local state
        setWallets(result);
      }

    } catch (error) {
      toast.error('Error fetching data:', error);
    }
  };
  const handleUnarchive = async (walletName: string) => {
    try {
      console.log("API UnArchive URL :" + `${ApiConfig["wallet"]}`)
      const response = await rawAPICall({
        method: "PUT",
        url: `${ApiConfig["wallet"]}` + "/" + auth?.userData?.uid + "/" + walletName + "/unArchive",
      });
      console.log("Respons : " + response);

      if (response.status == 200) {
        const result = await response.data;
        auth.userAddedWallets = result;
        // Update local state
        setWallets(result);
      }
      // setPostedData(response);
    } catch (error) {
      toast.error('Error fetching data:', error);
    }
  };
  const handleDelete = async (walletName: string) => {
    try {
      console.log("API Delete URL :" + `${ApiConfig["wallet"]}`)
      // Update local state
      // setArchiveWallets(updatedWallets);
      setIsDialogOpen(false);
      const response = await rawAPICall({
        method: "DELETE",
        url: `${ApiConfig["wallet"]}` + "/" + auth?.userData?.uid + "/" + walletName,
      });

      if (response.status == 200) {
        const updatedWallets = wallets.filter(wallet => wallet.walletName !== walletName);
        console.log("Updated wallets after delete:", updatedWallets);
        auth.userAddedWallets = updatedWallets;
        // Update local state
        setWallets(updatedWallets);
      }

      // setPostedData(response);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.warn("Wallet not found on the server (404). Updating local state.");
        const updatedWallets = wallets.filter(wallet => wallet.walletName !== walletName);
        auth.userAddedWallets = updatedWallets;
        setWallets(updatedWallets);
       // toast.info(`Wallet "${walletName}" not found on the server, but it has been removed locally.`);
    } else {
        // Handle other errors
        console.error("Error deleting wallet:", error);
        toast.error("An error occurred while deleting the wallet. Please try again.");
    }
   //  toast.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    console.log("Wallets state updated:", wallets);
  }, [wallets]);

  return (
    <Box>
      <Box className={classes.walletContainer}>
        <Box className={classes.contentContainer}>
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <Typography className={classes.textClr}
              variant="h5"
              sx={(theme) => ({
                //color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000000", // White for dark, black for light
                //color:theme.name === "LIGHT" ? "#000 !important" : "#FFF !important",
                fontFamily: '"Manrope", "Helvetica", "Arial", sans-serif',
                fontWeight: 600,
                textTransform: "none",
              })}
            >
              Wallets
            </Typography>
            <Button
              variant="contained"
              component={Link}
              to="/add-assets"
              sx={{
                background:
                  "linear-gradient(180deg, rgb(95,81,222) 0%, rgb(173,93,211) 100%)",
                color: "#FFF",
                borderRadius: "12px",
                padding: "8px 16px",
                fontFamily: '"Manrope", Helvetica',
                fontWeight: 600,
                textTransform: "none",
              }}
            >
              Add Wallet
            </Button>

          </Box>

          {/* Content */}
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : wallets.length > 0 ? (
            <Grid container spacing={3}>
              {wallets.filter(wallet => !wallet.archived).map((wallet, index) => (
                <Grid item xs={12} sm={6} md={4} key={wallet.walletId || index}>
                  <Paper className={classes.walletCard}>
                    {/* Wallet Header */}
                    <Box className={classes.walletHeader}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                        <Box className={classes.walletIcon}>
                          <img
                            src={wallet.imageUrl}
                            //alt="KuCoin Icon"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography variant="h6" sx={{ fontFamily: '"Manrope", Helvetica', fontWeight: 600, }}>
                            {wallet.walletName
                              ? wallet.walletName
                                .split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                .join(' ')
                              : "Unnamed Wallet"}
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              color: '#83818E',
                              fontFamily: '"Manrope", Helvetica',
                              fontWeight: 600,
                            }}
                          >
                            {wallet.walletId ? `${wallet.walletId.slice(0, 20)}...` : "N/A"}
                          </Typography>

                        </Box>
                      </Box>
                      <Box>
                      <IconButton
                          style={{ color: "#83818E",}}
                          onClick={() => openDialog(wallet.walletName, "archive")}
                          disabled={wallet.isDeleting} // Disable button when deleting
                        >
                             <img
                            src="images/walletDelete.svg"
                          />
                          {/* {wallet.isDeleting ? <CircularProgress size={20} color="inherit" /> : <Delete />} */}
                        </IconButton>
                        <IconButton style={{ color: '#83818E', width:"50px" }}>
                        <img
                            src="images/refresh.svg"
                          />
                        </IconButton>
                       


                      </Box>
                    </Box>

                    {/* Content Section */}
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Box>
                        <Typography style={{ color: '#83818E', fontFamily: '"Manrope", Helvetica', fontWeight: 600 }} className={classes.sectionTitle}>
                          Total Volume
                        </Typography>
                        <Typography className={classes.sectionValue}>
                          {wallet.totalVolume || "NA"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography style={{ color: '#83818E', fontFamily: '"Manrope", Helvetica', fontWeight: 600 }} className={classes.sectionTitle}>
                          Number of Transactions
                        </Typography>
                        <Typography className={classes.sectionValue}>
                          {wallet.numberOfTransactions || "NA"}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Footer */}
                    <Typography style={{ color: '#83818E', marginRight: '168px' }} className={classes.footerText}>
                      Updated {wallet.updatedTime || "NA"} min ago
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography className={classes.textClr}
              variant="body1"
              sx={{
                color: theme.name === "LIGHT" ? "#00000" : "#FFFFF",
                padding: 2,
              }}
            >
              No wallets added yet.
            </Typography>
          )}
        </Box>
        {/* This is Code for Archived Wallets */}

      </Box>

      <Box className={classes.walletContainer}>
        <Box className={classes.contentContainer}>
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <Typography className={classes.textClr}
              variant="h5"
              sx={(theme) => ({
                //color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000000", // White for dark, black for light
                //color:theme.name === "LIGHT" ? "#000 !important" : "#FFF !important",
                fontFamily: '"Manrope", "Helvetica", "Arial", sans-serif',
                fontWeight: 600,
                textTransform: "none",
              })}
            >
              Archived Wallets
            </Typography>
          </Box>

          {/* Content */}
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : wallets.filter(wallet => wallet.archived).length > 0 ? (
            <Grid container spacing={3}>
              {wallets.filter(wallet => wallet.archived).map((wallet, index) => (
                <Grid item xs={12} sm={6} md={4} key={wallet.walletId || index}>
                  <Paper className={classes.walletCard}>
                    {/* Wallet Header */}
                    <Box className={classes.walletHeader}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                        <Box className={classes.walletIcon}>
                          <img
                            src={wallet.imageUrl}
                            //alt="KuCoin Icon"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography variant="h6" sx={{ fontFamily: '"Manrope", Helvetica', fontWeight: 600, }}>
                            {wallet.walletName
                              ? wallet.walletName
                                .split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                .join(' ')
                              : "Unnamed Wallet"}
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              color: '#83818E',
                              fontFamily: '"Manrope", Helvetica',
                              fontWeight: 600,
                            }}
                          >
                            {wallet.walletId ? `${wallet.walletId.slice(0, 20)}...` : "N/A"}
                          </Typography>

                        </Box>
                      </Box>
                      <Box>
                        <IconButton style={{ color: '#83818E' }}>
                          <UndoIcon onClick={() => openDialog(wallet.walletName, "unarchive")} />
                        </IconButton>
                        <IconButton
                          style={{ color: "#83818E" }}
                          onClick={() => openDialog(wallet.walletName, "delete")}
                          disabled={wallet.isDeleting} // Disable button when deleting
                        >
                          {wallet.isDeleting ? <CircularProgress size={20} color="inherit" /> : <Delete />}
                        </IconButton>


                      </Box>

                    </Box>

                    {/* Content Section */}
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Box>
                        <Typography style={{ color: '#83818E', fontFamily: '"Manrope", Helvetica', fontWeight: 600 }} className={classes.sectionTitle}>
                          Total Value
                        </Typography>
                        <Typography className={classes.sectionValue}>
                          {wallet.totalVolume || "NA"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography style={{ color: '#83818E', fontFamily: '"Manrope", Helvetica', fontWeight: 600 }} className={classes.sectionTitle}>
                          Number of Transactions
                        </Typography>
                        <Typography className={classes.sectionValue}>
                          {wallet.numberOfTransactions || "NA"}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Footer */}
                    <Typography style={{ color: '#83818E', marginRight: '168px' }} className={classes.footerText}>
                      Updated {wallet.updatedTime || "NA"} min ago
                    </Typography>
                  </Paper>
                  {/* AlertDialog Component */}
                  {/* <AlertDialog
                    open={isDialogOpen}
                    onClose={closeDialog}
                    onConfirm={() => handleDelete(wallet.walletName)}
                    title="Confirm Delete !"
                    description={"Are you sure you want to delete the wallet? This action cannot be undone."}
                  /> */}

                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography className={classes.textClr}
              variant="body1"
              sx={{
                color: theme.name === "LIGHT" ? "#00000" : "#FFFFF",
                padding: 2,
              }}
            >
              No Archived wallets .
            </Typography>
          )}
        </Box>
        {/* This is Code for Archived Wallets */}

      </Box>
      <AlertDialog
        open={dialogData.isOpen}
        onClose={closeDialog}
        onConfirm={() => handleAction(dialogData.walletName, dialogData.action)}
        title={dialogData.title}
        description={dialogData.description}
      />
    </Box>


  );
};

export default Wallets;