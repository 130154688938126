import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { getAPIHandler } from 'src/Apiconfig/service'
import { v4 as uuidv4 } from 'uuid'
import { calculateTimeLeft } from 'src/utils'
import { onAuthStateChanged } from 'firebase/auth'
import { firebaseAuth } from 'src/firebase'
export const AuthContext = createContext()

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('token', accessToken)
    axios.defaults.headers.common.Authorization = `${accessToken}`
  } else {
    localStorage.removeItem('token')
    delete axios.defaults.headers.common.Authorization
  }
}

function checkLogin() {
  const accessToken = window.localStorage.getItem('accessToken')
  return accessToken ? true : false
}

export default function AuthProvider(props) {
  const UUID = uuidv4()
  const [endTime, setEndtime] = useState()
  const source = axios.CancelToken.source()
  const [isLogin, setIsLogin] = useState(checkLogin())
  const [userAddedWallets, setUserAddedWallets] = useState([])
  const [timeLeft, setTimeLeft] = useState()
  const [userData, setUserData] = useState({})
  const [disableHeaders, setDisableHeaders] = useState(false);

  //function to fetch all the added user wallets
  const getAllAddedWallets = async (source) => {
    try {
      const response = await getAPIHandler({
        endPoint: 'wallet',
        id: userData?.uid,
        source: source,
        token: window.localStorage.getItem('accessToken'),
      });
  
      if (response?.status === 200 && response.data.length > 0) {
        setUserAddedWallets(response.data);
      } else {
        setUserAddedWallets([]);
        setDisableHeaders(true); // Disable headers if response is empty
      }
    } catch (error) {
      console.log(error);
      setUserAddedWallets([]);
      setDisableHeaders(true); // Disable headers in case of error
    }
  };
  

  // const getUserProfileData = async () => {
  //   try {
  //     onAuthStateChanged(firebaseAuth, (user) => {
  //       // console.log('user---', user)
  //       setUserData(user)
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  const getUserProfileData = async () => {
    try {
      onAuthStateChanged(firebaseAuth, (user) => {
        if (user) {
          console.log('Authenticated User:', user);
          setUserData(user);
        } else {
          console.log('No user is signed in');
        }
      });
    } catch (error) {
      console.log('Error fetching user profile data:', error);
    }
  };
  

  //context data object that has been passed to the data layer of the application
  let data = {
    UUID,
    timeLeft,
    userData,
    userAddedWallets,
    userLoggedIn: isLogin,
    userLogIn: (type, data) => {
      // setSession(data)
      setIsLogin(type)
    },
    setUserData: (data) => setUserData(data),
    setEndtime: (data) => setEndtime(data),
    getAllAddedWallets: () => getAllAddedWallets(source),
  }

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000))
      }, 1000)
      return () => clearTimeout(timer)
    }
  })

  //onload function to throw all required data in the application layer
  useEffect(() => {
    if (isLogin) {
      getUserProfileData()
      // getAllAddedWallets(source)
      //clean up function
      return () => {
        source.cancel()
      }
    }
  }, [isLogin])

  useEffect(() => {
    if (userData?.uid) {
      getAllAddedWallets(source)
    }
  }, [userData])

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  )
}
