import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';

const firebaseConfig = {
  apiKey: 'AIzaSyAOLYZj26o-7QpyaKX1z-uXSCI4gWkoxhU',
  authDomain: 'dev-prj-frontend-apps.firebaseapp.com',
  projectId: 'dev-prj-frontend-apps',
  storageBucket: 'dev-prj-frontend-apps.appspot.com',
  messagingSenderId: '594081517864',
  appId: '1:594081517864:web:286fb73c36177dd82fc01d',
  measurementId: 'G-VRMXVH6KQX',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth();

function  useHandleLogin  ()  {
  useEffect(() => {
    const auth = getAuth();
    let refreshTokenInterval;

    const unsubscribe = onAuthStateChanged(firebaseAuth, async (currentUser) => {
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        console.log('Initial token:', idToken);
        window.localStorage.setItem("accessToken",idToken);
        refreshTokenInterval = setInterval(async () => {
          try {
            const refreshedToken = await currentUser.getIdToken(true);
            window.localStorage.setItem("accessToken",refreshedToken);
            console.log('Token refreshed:', refreshedToken);
          } catch (error) {
            console.error('Error refreshing token:', error);
          }
        }, 10 * 60 * 1000); //Every 10 mins this will hit and get the new Token.
      } else {
        if (refreshTokenInterval) {
          clearInterval(refreshTokenInterval);
        }
      }
    });

    return () => {
      unsubscribe();
      if (refreshTokenInterval) {
        clearInterval(refreshTokenInterval);
      }
    };
  }, []);
};

export { app, firebaseAuth, useHandleLogin };